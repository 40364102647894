import React, { FC } from "react"
import { Helmet } from 'react-helmet'
import Link from 'next/link'

import vromoLogo from "@fleeteng/shared-library/media/vromo-logo.png"
import vromoFavicon from "@fleeteng/shared-library/media/cropped-vromo-favicon.png"

import styles from "./style.module.css"
import { layoutText } from "."
import { LayoutProps } from "./layout.model"

require("../../sentry.client.config")



export const Layout1: FC<LayoutProps> = ({ pageTitle, orderProvider, children }) => {

    const siteTitle = [orderProvider, layoutText.integrationSrv].join(" ").trim().toUpperCase()

    return (
        <React.Fragment>
            <Helmet>
                <title>
                    {`${pageTitle} | ${siteTitle}`}
                </title>
                <link
                    rel="shortcut icon"
                    href={vromoFavicon}
                    type="image/x-icon"
                />
            </Helmet>
            <div style={{ textAlign: 'center' }}>
                <a href="/">
                    <img src={vromoLogo}
                        alt="Vromo logo"
                        className={styles.logoImage}
                    />
                </a>

                <h1>
                    {siteTitle}
                </h1>
                <div>
                    {children}
                </div>
            </div>
        </React.Fragment>

    )
};


